import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/LayoutView'
import PageTitle from '@components/PageTitle'
import Section from '@components/Section'

const Page404 = () => (
  <Layout title='Page Not Found'>
    <div className='container my-4'>
      <div style={{ marginTop: 200 }}>
        <PageTitle
          header="Sorry, this page isn't available."
          subheader='You may have mistyped the address or the page may have moved.'
        />
      </div>
      <Section>
        <Link className='btn btn-yellow' to='/'>
          Go to homepage
        </Link>
      </Section>
    </div>
  </Layout>
)

export default Page404
